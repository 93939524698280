<template>
<div>
	<CRow>
		<CCol sm="6" md="8">
        <CCard>
          <CCardHeader>
            <h4>E-Wallet Rollback</h4>      
          </CCardHeader>
          <CCardBody> 

            <CRow>
              <CCol col="12" class="text-left">                   
                <CAlert v-bind:color="color" v-if="seen" id="alert">{{ msg }}</CAlert>
              </CCol> 
            </CRow>

             <b-row>
                  <b-col sm="3">                    
                     <label for="input-small" >Transaction ID</label>
                  </b-col>   
                  <b-col sm="9"> 
                      <vue-typeahead-bootstrap
                        v-model="transactId"              
                        :data="opttrId">
                        <template #append>
                          <CButton color="success" v-on:click="actionChange()">Select</CButton>
                        </template>
                      </vue-typeahead-bootstrap>
                   </b-col> 
                  </b-col>
              </b-row><br/> 

              <div v-if="info">

              <CInput
                type="text" 
                label="Transaction Date"
                v-model="trdate"
                :readonly="true"
                horizontal                
              />

              <CInput
                type="text" 
                label="Received From"
                v-model="sender"
                :readonly="true"
                horizontal                
              />

              <CInput
                type="text" 
                label="Received Phone No"
                v-model="senderPhone"
                :readonly="true"
                horizontal                
              />

              <CInput
                type="text" 
                label="Sent To"
                v-model="beneficiary"
                :readonly="true"
                horizontal                
              />

              <CInput
                type="text" 
                label="Sent Phone No"
                v-model="beneficiaryPhone"
                :readonly="true"
                horizontal                
              />

              <CInput
                type="text" 
                label="Currency"
                v-model="currency"
                :readonly="true"
                horizontal                
              />

              <CInput
                type="text" 
                label="Amount"
                v-model="amount"
                :readonly="true"
                horizontal                
              />

              <CInput
                type="text" 
                label="Type"
                v-model="movementType"
                :readonly="true"
                horizontal                
              />

              <CInput
                type="text" 
                label="Status"
                v-model="status"
                :readonly="true"
                horizontal                
              />

              </div>

              <!--CInput               
                label="Rollback Amount"
                type="number" min=0 step="any"
                v-model="amountrb"                
                horizontal                
              /-->

              <CTextarea
                label="Reason"
                v-model="reason"  
                placeholder="Type Reason..."
                horizontal
                rows="3"               
              />              

              <b-row>
                  <b-col sm="3"></b-col>   
                  <b-col sm="9">
                    <b-button-group>
                    <b-button style="width:100px;" v-on:click="actionReset()" variant="secondary">Reset</b-button>  
                    <b-button style="width:100px;" v-on:click="actionRollback()" variant="primary">Rollback</b-button>
                    </b-button-group>
                  </b-col>                  
              </b-row><br/>        
                

          </CCardBody>
        </CCard>
      </CCol>

     </CRow>

</div>
</template>


<script>
 import AuthService from '@/api/AuthService.js';
 import TransactService from '@/api/TransactService.js';
 import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap';

  export default { 
    components: {
      VueTypeaheadBootstrap,      
    },      
    data() {
      return { 
          transactId:'',
          trdate:'',
          sender:'',
          senderPhone:'',
          beneficiary:'',
          beneficiaryPhone:'',
          currency:'',
          amount:'',
          amountrb:null,
          movementType:'',
          status:'',
          opttrId:[],
          basetr:[],          
          reason:'', 
          info:false,      
          seen:false,
          msg :'',
          color:''          
        } ;      
      
    },
    created(){        
       this.retrieveTransac();
    },

    methods: {

      actionChange(){

        var trId  = this.transactId;
        let isnum = /^\d+$/.test(trId);

        if(trId && isnum){
          var trId   = this.transactId;
          var trs    = this.basetr;       
          let index  = trs.findIndex(trs => trs.transactionId === trId );

          if(index>=0){
            var select = trs[index];                
            this.trdate = select.transactionDate;
            this.sender = select.sender;
            this.senderPhone = select.senderPhone.number;
            this.beneficiary = select.beneficiary;
            this.beneficiaryPhone = select.beneficiaryPhone.number;
            this.currency = select.currency;
            this.amount = select.amount; this.amountrb = select.amount;
            this.movementType = select.movementType;
            this.status = select.status;
            this.info = true;
          } else {
            this.msg   = 'Transaction Type Not Allowed !'; 
            this.color = 'warning'; this.seen  = true;
            setTimeout( () =>  this.seen=false , 5000);
          }

        } else {
            this.msg   = 'Please find transactions first and fill the valid Number !'; 
            this.color = 'warning'; this.seen  = true;
            setTimeout( () =>  this.seen=false , 5000);
        }

      },

      actionRollback(){

          let params = {}; var validate = [];
          params['movementRef'] = this.transactId;
          params['amount'] = this.amountrb;
          params['reason'] = this.reason;

          for (var key in params) {
            if(!params[key]) { validate.push(false); } else { validate.push(true); }
          }   

          if(!validate.includes(false)){  //console.log('submit');
            this.P2PRollback(params);           
          } else {           
            this.msg   = 'Data Not Complete, Reason and Amount are Required!'; 
            this.color = 'warning'; this.seen  = true;
            setTimeout( () =>  this.seen=false , 5000);
          } 

      },

      actionReset(){
          this.transactId='';
          this.trdate = '';
          this.sender = '';
          this.senderPhone = '';
          this.beneficiary = '';
          this.beneficiaryPhone = '';
          this.currency = '';
          this.amount = '';
          this.amountrb = '';
          this.movementType = '';
          this.status = '';
          this.reason = '';
      },


      retrieveTransac(){ 

            AuthService.getConfigList().then(resp => { 

                var currSrc=[]; let params = {}; 
                var arrcurrency = resp.currency;
                for (const item of arrcurrency){ currSrc.push(item); } 
                
                params["userId"] = null;
                params["currency"] = currSrc;
                params["searchString"] = null;
                params["movementType"] = "P2P";
                params["fromDate"] = null;
                params["toDate"] = null;
                params["fromRange"] = null;
                params["toRange"] = null;
                params["movementStatus"] = [];
                params["startTime"] = null; 
                params["timeRange"] = null; 

                AuthService.getUserTransact(params).then((resp) => { //console.log(resp);

                  var items = []; var base = [];
                  for (var key in resp) {
                     items.push(resp[key].transactionId); base.push(resp[key]);                     
                  }

                  this.opttrId = items;  
                  this.basetr  = base;

                }).catch((e) => { console.log(e); }); 

            }).catch((e) => { console.log(e); });

      },     

      P2PRollback: function(params) { 
           TransactService.P2PRollback(params).then(resp => { 

              if(resp.message=='Success'){
                this.msg   = 'Rollback Successfully '; 
                this.color = 'success';  this.seen  = true;
                this.actionReset(); this.info = false;
              } else {
                this.msg   = resp.data.message; 
                this.color = 'warning'; this.seen  = true;                
              }

              setTimeout( () =>  this.seen=false , 5000);

           }, error => {
            console.log('error');    
          });
      },     
      
    }
  }
</script>